import React, { useState } from "react";

export default function Header({ activeHelp = true }) {
  //state
  const [help, setHelp] = useState(false);
  const [animation, setAnimation] = useState("up");

  //functions
  const handleClick = () => {
    if (help) {
      setAnimation("down");
      setTimeout(() => {
        setHelp(!help);
      }, 1000);
    } else {
      setAnimation("up");
      setHelp(!help);
    }
  };

  const helpPopup = (
    <div className={`overlay ${animation}-animation`}>
      <div className={`overlay-container`}>
        <div
          data-testid="help-exit-cross"
          className="exit-cross"
          onClick={handleClick}
        ></div>
        <div className="overlay-content">
          <h2>Ayuda</h2>
          {/* <p>
            <span>Comisión de la operación:</span> Por defecto está marcada en
            un 6%.
          </p>
          <p>
            <span>Participación de consultor:</span> Esta será del 100% si el
            consultor capta y vende la propiedad. De 60% si solo capta la
            propiedad y del 40% si solo vende la propiedad.
          </p>
          <p>
            <span>Comisión generada por el consultor:</span> Es el valor total
            de la comisión total de la operación.
          </p>
          <p>
            <span>Marketing fee:</span> Es un concepto que se destina al
            departamento de marketing, siendo un 2,5% de la comisión generada
            por el consultor.
          </p>
          <p>
            <span>Tramo del consultor:</span> Es el porcentaje que le
            corresponde al consultor de la comisión generada. Este porcentaje
            varía en función del tramo de facturación en el que se encuentre el
            consultor a lo largo del año.
          </p>
          <p>
            <span>Comisión según tramo del consultor:</span> Son los honorarios
            que genera el consultor.
          </p>
          <p>
            <span>Gastos de administración:</span> Son los gastos que
            corresponden al departamento de finanzas, siendo de 150 € para un
            operación de captación y venta, de 90 € para una operación de
            captación y 60 € para una operación de venta.
          </p>
          <p>
            <span>Base a facturar antes de impuestos:</span> Son los honorarios
            totales del consultor.
          </p> */}
        </div>
      </div>
    </div>
  );

  //template
  return (
    <header>
      <div className="header-content">
        <div className="logo">
          <a href="https://www.engelvoelkers.com/es/">
            <img src="E_V_Logo_RGB_oReg.jpg" alt="engel-voelkers-logo" />
          </a>
        </div>
        {activeHelp && (
          <button
            className="btn-tertiary"
            disabled={help}
            onClick={handleClick}
          >
            Ayuda
          </button>
        )}

        {help ? helpPopup : null}
      </div>
    </header>
  );
}
