import React from "react";
import Table from "../Inputs/Table";
import PropTypes from "prop-types";

const StageThree = ({ fields, setResults }) => {
  //template
  return (
    <div className="stage up-animation">
      <h2>Resumen</h2>
      <Table fields={fields} />
      <button onClick={() => setResults(true)} className="btn-primary">
        Calcular plan
      </button>
    </div>
  );
};

StageThree.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default StageThree;
