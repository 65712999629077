import React, { useState, useEffect, useRef } from "react";
// import InfoPopup from "./InfoPopup";
import { formatNumber } from "../../tools/formatNumber";
import PropTypes from "prop-types";

function Slider({
  maxValue,
  minValue,
  stepValue,
  units,
  label,
  name,
  fields,
  setFields,
  infoPopup,
}) {
  //state
  const [sliderStyle, setSliderStyle] = useState({});
  const [focus, setFocus] = useState(false);

  //refs
  const inputRng = useRef(null);

  //effects
  useEffect(() => {
    changeRangeStyle();
  }, [fields[name]]);

  //constants and functions
  let acceptedKeys = [
    8, 9, 13, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98,
    99, 100, 101, 102, 103, 104, 105,
  ];

  const handleFocus = () => {
    setFocus(true);
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (isNaN(value) || value === "") {
      setFields({ ...fields, [name]: 0 });
    } else {
      setFields({ ...fields, [name]: Number.parseInt(value) });
    }
  };

  const handleKeyDown = (e) => {
    //Limited to number, arrows and delete buttons
    if (!acceptedKeys.includes(e.keyCode)) {
      e.preventDefault();
    } else if (e.keyCode === 13) {
      inputRng.current.blur();
    }
  };

  const handleBlur = (e) => {
    setFocus(false);
    let value = Number.parseInt(e.target.value);
    //In case user blur without write a number (value = NaN)
    if (isNaN(value)) {
      value = minValue;
    } else if (value > maxValue) {
      value = maxValue;
    } else if (value < minValue) {
      value = minValue;
    } else if (value % stepValue !== 0) {
      while (value % stepValue !== 0) {
        value++;
      }
    }
    setFields({ ...fields, [name]: value });
  };

  const changeRangeStyle = () => {
    let value =
      fields[name] !== ""
        ? ((fields[name] - minValue) / (maxValue - minValue)) * 100
        : 50;
    setSliderStyle({
      background: `linear-gradient(to right,#ff0000 0%,#ff0000 ${value}%, var(--line-color) ${value}%, var(--line-color) 100%)`,
    });
  };

  const renderTypeOfConsultant = () => {
    let seller =
      fields[name] > 0 ? `${formatNumber(fields[name], units)} Seller` : "";
    let buyer =
      100 - fields[name] > 0
        ? `${formatNumber(100 - fields[name], units)} Buyer`
        : "";

    return (
      <p className="type-of-consultant">{`${buyer} ${
        buyer && seller ? "-" : ""
      } ${seller}`}</p>
    );
  };

  //template
  return (
    <div className="slider-area">
      <div className="input-field">
        {/* {infoPopup ? <InfoPopup content={infoPopup} /> : false} */}
        <label htmlFor="intro-range-value">{label}</label>
        {name === "consultantType" ? (
          renderTypeOfConsultant()
        ) : (
          <input
            ref={inputRng}
            id="intro-range-value"
            name={name}
            type="text"
            value={focus ? fields[name] : formatNumber(fields[name], units)}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        )}
      </div>
      <div className="range-field">
        <div className="slider-labels">
          <p data-testid="slider-min-value">
            {name === "consultantType"
              ? "Buyer"
              : formatNumber(minValue, units)}
          </p>
          <p data-testid="slider-max-value">
            {name === "consultantType"
              ? "Seller"
              : formatNumber(maxValue, units)}
          </p>
        </div>
        <input
          style={
            name === "consultantType"
              ? { backgroundColor: "var(--line-color)" }
              : sliderStyle
          }
          className="slider"
          type="range"
          max={maxValue}
          min={minValue}
          step={stepValue}
          name={name}
          value={fields[name]}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default Slider;

Slider.propTypes = {
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  stepValue: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired, //currency or percent
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFields: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  infoPopup: PropTypes.string, //Not required because it is optional
};
