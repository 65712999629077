import React from "react";

export default function Footer() {
  //template
  return (
    <footer>
      <img src="EV_Signet_RGB-2.png" alt="" />
    </footer>
  );
}
