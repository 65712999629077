const sites = ["MAD", "BCN", "VAL"];

const initialStateOfFields = {
  commissionTarget: 30000,
  propertyPrice: 500000,
  marketCenter: sites[Math.floor(Math.random() * 3)],
  commission: 3,
  consultantSection: 50,
  // consultantParticipation: 45,
  consultantType: 50,
};

const teamsPrices = {
  MAD: {
    S1: 1400000,
    S2: 1000000,
    S3: 850000,
    S4: 650000,
    S5: 900000,
    S6: 525000,
    S7: 700000,
    S8: 600000,
    S9: 725000,
    S11: 450000,
    S14: 475000,
  },
  BCN: {
    S1: 875000,
    S2: 600000,
    S3: 500000,
    S4: 1100000,
    S5: 400000,
    S6: 650000,
    S7: 475000,
    S8: 370000,
    S9: 315000,
    S10: 460000,
    S11: 470000,
    S12: 330000,
  },
  VAL: {
    S1: 265000,
    S2: 285000,
    S3: 330000,
    S4: 350000,
  },
};

// const teamsPrices = {
//   MAD: 800000,
//   BCN: 560000,
//   VAL: 310000,
// };

const ratios = {
  MAD: {
    buy: {
      escrituras: 100, //pendiente dato final
      arras: 55.79,
      ofertas: 5.94,
      visitas: 0.42,
    },
    sell: {
      arras: 33.46,
      captaciones: 45.62,
      listing: 80.0,
      valoraciones: 70.0,
      leadOwner: 3.5, //pendiente dato final
    },
  },
  BCN: {
    buy: {
      escrituras: 100,
      arras: 55.56,
      ofertas: 7.2,
      visitas: 3.98,
    },
    sell: {
      arras: 33.33,
      captaciones: 75.0,
      listing: 80.0,
      valoraciones: 71.43,
      leadOwner: 3.5,
    },
  },
  VAL: {
    buy: {
      escrituras: 100, //pendiente dato final
      arras: 91.16,
      ofertas: 10.24,
      visitas: 6.0,
    },
    sell: {
      arras: 36.84,
      captaciones: 63.33,
      listing: 80.0,
      valoraciones: 70.0,
      leadOwner: 3.5, //pendiente dato final
    },
  },
  LIS: {
    buy: {
      escrituras: 100, //pendiente dato final
      arras: 27.83,
      ofertas: 15.33,
      visitas: 2.5,
    },
    sell: {
      arras: 15.65,
      captaciones: 56.18,
      listing: 80.0,
      valoraciones: 70.0,
      leadOwner: 3.5, //pendiente dato final
    },
  },
};

export { initialStateOfFields, teamsPrices, sites, ratios };
