import React from "react";
import { formatNumber } from "../../tools/formatNumber";

const Table = ({ fields }) => {
  //props
  let {
    commissionTarget,
    propertyPrice,
    marketCenter,
    commission,
    consultantSection,
    // consultantParticipation,
    consultantType,
  } = fields;

  //functions
  const buyerOrSeller = () => {
    let seller =
      consultantType > 0
        ? `${formatNumber(consultantType, "percent")} Seller`
        : "";
    let buyer =
      100 - consultantType > 0
        ? `${formatNumber(100 - consultantType, "percent")} Buyer`
        : "";

    return `${buyer} ${buyer && seller ? "-" : ""} ${seller}`;
  };

  const tableData = {
    "Market Center": marketCenter,
    "Honorarios objetivo": formatNumber(commissionTarget, "currency"),
    "Precio de la propiedad": formatNumber(propertyPrice, "currency"),
    "Comisión de la operación": formatNumber(commission, "percent"),
    "Tramo del consultor": formatNumber(consultantSection, "percent"),
    // "Participación del consultor": formatNumber(
    //   consultantParticipation,
    //   "percent"
    // ),
    "Tipo de consultor": buyerOrSeller(),
  };

  //template
  return (
    <table className="summary-table">
      <tbody>
        {Object.keys(tableData).map((row, i) => {
          return (
            <tr key={`row-${i}`}>
              <td>{row}</td>
              <td>{tableData[row]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
