import React from "react";
import Table from "./Inputs/Table";
import BuyerFunnel from "./BuyerFunnel";
import SellerFunnel from "./SellerFunnel";
import Header from "./Header";

const Pdf = React.forwardRef((props, ref) => {
  //props
  let { fields, buyerFunnelData, sellerFunnelData } = props;

  //template
  return (
    <div className="pdf-printed" ref={ref}>
      <Header activeHelp={false} />
      <h1>Business Plan</h1>
      <h2>Resumen</h2>
      <Table fields={fields} />
      <h2 style={{ marginTop: "60px" }}>Resultados</h2>
      <div className="pdf-funnel-container">
        {fields.consultantType < 100 && <BuyerFunnel data={buyerFunnelData} />}
        {fields.consultantType > 0 && <SellerFunnel data={sellerFunnelData} />}
      </div>
    </div>
  );
});

export default Pdf;
