import React from "react";

const Radio = ({ label, options, name, fields, setFields }) => {
  //functions
  const renderOptions = () => {
    return options.map((opt, i) => {
      return (
        <p
          key={`radio-${i}`}
          onClick={() => handleClick(opt)}
          className={`radio-option ${
            fields[name] === opt ? "selected-option" : ""
          }`}
        >
          {opt}
        </p>
      );
    });
  };

  const handleClick = (value) => {
    setFields({ ...fields, [name]: value });
  };

  //template
  return (
    <div className="radio-area">
      <div className="input-field">
        <label htmlFor="radio-input">{label}</label>
        <div className="radio-options">{renderOptions()}</div>
      </div>
    </div>
  );
};

export default Radio;
