import React from "react";
import Slider from "../Inputs/Slider";
import PropTypes from "prop-types";

const StageTwo = ({ fields, setFields }) => {
  //template
  return (
    <div className="stage">
      <div className="inputs-container">
        <Slider
          maxValue={6}
          minValue={1}
          stepValue={1}
          units="percent"
          label="Comisión de la operacion"
          name="commission"
          setFields={setFields}
          fields={fields}
        />
        <Slider
          maxValue={75}
          minValue={30}
          stepValue={5}
          units="percent"
          label="Tramo del consultor"
          name="consultantSection"
          setFields={setFields}
          fields={fields}
        />
        {/* <Select
          label="Participación del consultor"
          optionValues={[40, 60, 100]}
          units="percent"
          name="consultantParticipation"
          setFields={setFields}
          fields={fields}
        /> */}
        <Slider
          maxValue={100}
          minValue={0}
          stepValue={25}
          units="percent"
          label="Tipo de consultor"
          name="consultantType"
          setFields={setFields}
          fields={fields}
        />
      </div>
    </div>
  );
};

StageTwo.propTypes = {
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default StageTwo;
