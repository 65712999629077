const formatNumber = (num, type) => {
  let number;
  switch (type) {
    case "currency":
      number = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 0,
      }).format(num);
      break;
    case "percent":
      let num2 = num / 100;
      number = new Intl.NumberFormat("de-DE", {
        style: "percent",
      }).format(num2);
      break;
    default:
      number = null;
      break;
  }
  return number;
};

module.exports = {
  formatNumber: formatNumber,
};
