import React, { useState, useEffect } from "react";
import { formatNumber } from "../tools/formatNumber";
import { ratios } from "../tools/helper";

const SellerFunnel = ({ data }) => {
  // console.log("desde seller funnel", data);
  //props
  let { amount, commissionPerOp, marketCenter } = data;
  let usedRatios = ratios[marketCenter]["sell"];

  //state
  const [funnel, setFunnel] = useState({
    horasFarming: "",
    leadOwner: "",
    valoraciones: "",
    listing: "",
    captaciones: "",
    arras: "",
  });

  //effects
  useEffect(() => calculateFunnel(), [data]);

  //functions
  const calculateFunnel = () => {
    let arras = amount / parseFloat(commissionPerOp);
    let captaciones = arras / (parseFloat(usedRatios.arras) / 100);
    let listing = captaciones / (parseFloat(usedRatios.captaciones) / 100);
    let valoraciones = listing / (parseFloat(usedRatios.listing) / 100);
    let leadOwner = valoraciones / (parseFloat(usedRatios.valoraciones) / 100);
    let horasFarming = leadOwner / (parseFloat(usedRatios.leadOwner) / 100);

    let finalFunnel = {
      horasFarming: Math.round(horasFarming),
      leadOwner: Math.round(leadOwner),
      valoraciones: Math.round(valoraciones),
      listing: Math.round(listing),
      captaciones: Math.round(captaciones),
      arras: Math.round(arras),
    };

    setFunnel({ ...finalFunnel });
  };

  //template
  return (
    <div className="funnel funnel-seller">
      <h3>Funnel Seller</h3>
      <table>
        <tbody>
          <tr>
            <td>Horas de farming</td>
            <td>{funnel.horasFarming}</td>
          </tr>
          <tr>
            <td>Lead owner</td>
            <td>{funnel.leadOwner}</td>
          </tr>
          <tr>
            <td>Valoraciones</td>
            <td>{funnel.valoraciones}</td>
          </tr>
          <tr>
            <td>Listing presentation</td>
            <td>{funnel.listing}</td>
          </tr>
          <tr>
            <td>Captaciones/Activaciones</td>
            <td>{funnel.captaciones}</td>
          </tr>
          <tr>
            <td>Arras</td>
            <td>{funnel.arras}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Honorarios</td>
            <td>{formatNumber(amount, "currency")}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default SellerFunnel;
