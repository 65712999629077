import React, { useState, useEffect } from "react";
import { formatNumber } from "../tools/formatNumber";
import { ratios } from "../tools/helper";

const BuyerFunnel = ({ data }) => {
  //props
  let { amount, commissionPerOp, marketCenter } = data;
  let usedRatios = ratios[marketCenter]["buy"];

  //state
  const [funnel, setFunnel] = useState({
    exposes: "",
    visitas: "",
    ofertas: "",
    arras: "",
    escrituras: "",
  });

  //effects
  useEffect(() => calculateFunnel(), [data]);

  //functions
  const calculateFunnel = () => {
    let escrituras = amount / parseFloat(commissionPerOp);
    let arras = escrituras / (parseFloat(usedRatios.escrituras) / 100);
    let ofertas = arras / (parseFloat(usedRatios.arras) / 100);
    let visitas = ofertas / (parseFloat(usedRatios.ofertas) / 100);
    let exposes = visitas / (parseFloat(usedRatios.visitas) / 100);

    let finalFunnel = {
      exposes: Math.round(exposes),
      visitas: Math.round(visitas),
      ofertas: Math.round(ofertas),
      arras: Math.round(arras),
      escrituras: Math.round(escrituras),
    };

    setFunnel({ ...finalFunnel });
  };

  //template
  return (
    <div className="funnel funnel-buyer">
      <h3>Funnel Buyer</h3>
      <table>
        <tbody>
          <tr>
            <td>Exposes</td>
            <td>{funnel.exposes}</td>
          </tr>
          <tr>
            <td>Visitas</td>
            <td>{funnel.visitas}</td>
          </tr>
          <tr>
            <td>Ofertas</td>
            <td>{funnel.ofertas}</td>
          </tr>
          <tr>
            <td>Arras + ED</td>
            <td>{funnel.arras}</td>
          </tr>
          <tr>
            <td>Escrituras</td>
            <td>{funnel.escrituras}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Honorarios</td>
            <td>{formatNumber(amount, "currency")}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default BuyerFunnel;
