import React, { useState, Fragment } from "react";
import StageOne from "./StageOne";
import StageTwo from "./StageTwo";
import StageThree from "./StageThree";
import { initialStateOfFields } from "../../tools/helper";
import Results from "../Results";

const Stages = () => {
  //state
  const [fields, setFields] = useState(initialStateOfFields);
  const [stage, setStage] = useState(0);
  const [results, setResults] = useState(false);

  //functions
  const handleClick = (e) => {
    let { name } = e.target;
    let nextStage = stage;
    switch (name) {
      case "nextStage":
        if (stage < 2) nextStage++;
        break;
      case "previousStage":
        if (stage > 0) nextStage--;
        break;
      default:
        break;
    }
    setStage(nextStage);
  };

  //render
  return (
    <Fragment>
      {results ? (
        <Results results={results} setResults={setResults} fields={fields} />
      ) : (
        <>
          <section className="stages-container">
            <div className="stages-wrapper" style={{ "--i": stage }}>
              <StageOne fields={fields} setFields={setFields} />
              <StageTwo fields={fields} setFields={setFields} />
              <StageThree fields={fields} setResults={setResults} />
            </div>
          </section>
          <div className="stage-marker">
            <div
              className={`marker ${stage === 0 ? "active-marker" : ""}`}
            ></div>
            <div
              className={`marker ${stage === 1 ? "active-marker" : ""}`}
            ></div>
            <div
              className={`marker ${stage === 2 ? "active-marker" : ""}`}
            ></div>
          </div>
          <div className="button-container">
            <button
              onClick={handleClick}
              name="previousStage"
              className={stage === 0 ? "btn-disabled" : "btn-primary"}
              disabled={stage === 0}
            >
              Previa
            </button>
            <button
              onClick={handleClick}
              name="nextStage"
              className={stage === 2 ? "btn-disabled" : "btn-primary"}
              disabled={stage === 2}
            >
              Siguiente
            </button>
          </div>
        </>
      )}
    </Fragment>
  );
};

//pending proptypes

export default Stages;
