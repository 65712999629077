import React from "react";
import PropTypes from "prop-types";
import Slider from "../Inputs/Slider";
import Radio from "../Inputs/Radio";
import { teamsPrices, sites } from "../../tools/helper";

const StageOne = ({ fields, setFields }) => {
  //functions
  const renderTeams = () => {
    let teams = Object.keys(teamsPrices[fields.marketCenter]);
    return teams.map((team, i) => {
      return (
        <option
          key={`team-${i}`}
          value={teamsPrices[fields.marketCenter][team]}
        >
          {team}
        </option>
      );
    });
  };

  const selectTeam = (e) => {
    let { value } = e.target;
    if (isNaN(value)) value = 500000;
    setFields({ ...fields, propertyPrice: value });
  };

  //template
  return (
    <div className="stage">
      <div className="inputs-container">
        <Radio
          label="¿A qué Market Center perteneces?"
          options={sites}
          name="marketCenter"
          setFields={setFields}
          fields={fields}
        />
        <Slider
          maxValue={100000}
          minValue={0}
          stepValue={100}
          units="currency"
          label="¿Cuáles son tus objetivos de honorarios anuales?"
          name="commissionTarget"
          setFields={setFields}
          fields={fields}
        />
        <div>
          <Slider
            maxValue={5000000}
            minValue={0}
            stepValue={1000}
            units="currency"
            label="¿Cual es el precio medio de la propiedades?"
            name="propertyPrice"
            setFields={setFields}
            fields={fields}
          />
          <div className="team-selector">
            <label htmlFor="team">Seleccionar equipo</label>
            <select onChange={selectTeam} name="team">
              <option>-</option>
              {renderTeams()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

StageOne.propTypes = {
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default StageOne;
