import React, { useState, useEffect, useRef } from "react";
import BuyerFunnel from "./BuyerFunnel";
import SellerFunnel from "./SellerFunnel";
import Spinner from "./Spinner";
import Pdf from "./Pdf";
import pdfLogo from "../icons/file-pdf-solid.svg";

import { useReactToPrint } from "react-to-print";

const Results = ({ results, setResults, fields }) => {
  //props
  let {
    commissionTarget,
    propertyPrice,
    marketCenter,
    commission,
    consultantSection,
    // consultantParticipation,
    consultantType,
  } = fields;

  //refs
  const printRef = useRef();

  //state
  const [animation, setAnimation] = useState("up-animation");
  const [loading, setLoading] = useState(true);
  const [buyerFunnelData, setBuyerFunnelData] = useState({
    amount: "",
    commissionPerOp: "",
    marketCenter: marketCenter,
  });
  const [sellerFunnelData, setSellerFunnelData] = useState({
    amount: "",
    commissionPerOp: "",
    marketCenter: marketCenter,
  });

  //effects
  useEffect(() => calculatePlan(), []);

  //functions
  const handleClick = () => {
    setAnimation("down-animation");
    setTimeout(() => {
      setResults(!results);
    }, 350);
  };

  const renderBuyerFunnel = () => {
    if (consultantType < 100) {
      return <BuyerFunnel data={buyerFunnelData} />;
    } else return null;
  };

  const renderSellerFunnel = () => {
    if (consultantType > 0) {
      return <SellerFunnel data={sellerFunnelData} />;
    } else return null;
  };

  const calculatePlan = () => {
    //1 Calculate commission amount
    // console.log("step 1--------------");
    let commissionAmount = (propertyPrice * commission) / 100;
    // console.log("commissionAmount", commissionAmount);

    //2 Get participation amount (buyer/seller);
    // console.log("step 2--------------");
    // console.log("consultantType", consultantType);

    //si es valencia, es 50%/50% captacion y venta
    let participationBuyer =
      marketCenter === "VAL" ? 0.5 * commissionAmount : 0.4 * commissionAmount;
    let participationSeller =
      marketCenter === "VAL" ? 0.5 * commissionAmount : 0.6 * commissionAmount;
    // console.log("buyer", participationBuyer, "seller", participationSeller);

    //3 Get final commission per type and operation
    // console.log("step 3--------------");
    let finalCommisionBuyer = (participationBuyer * consultantSection) / 100; //ENVIAR A FUNNEL
    let finalCommisionSeller = (participationSeller * consultantSection) / 100; //ENVIAR A FUNNEL
    // console.log(
    //   "comisiones finales por operacion",
    //   finalCommisionSeller,
    //   finalCommisionBuyer
    // );

    //4 Obtain commission % due to type of consultant
    // console.log("step 4--------------");
    let buyerOperationAmount =
      ((100 - consultantType) * finalCommisionBuyer) / 100;
    let sellerOperationAmount = (consultantType * finalCommisionSeller) / 100;
    // console.log("seller / buyer", sellerOperationAmount, buyerOperationAmount);

    //5 Plus both commmission per operation
    // console.log("step 5-----------");
    let averageCommissionAmount = sellerOperationAmount + buyerOperationAmount;
    // console.log("comission media por operacion", averageCommissionAmount);

    //6 Get average number of operation
    // console.log("step 6---------------");
    let numberOfOperations = commissionTarget / averageCommissionAmount;
    // console.log("numero de operaciones", numberOfOperations);

    //7 Get total amount per operation
    // console.log("step 7---------");
    let totalBuyerAmount = numberOfOperations * buyerOperationAmount; //ENVIAR A FUNNEL
    let totalSellerAmount = numberOfOperations * sellerOperationAmount; //ENVIAR A FUNNEL
    // console.log(
    //   "total importe por operacion",
    //   totalSellerAmount,
    //   totalBuyerAmount
    // );

    //Save data in state
    setBuyerFunnelData({
      ...buyerFunnelData,
      amount: totalBuyerAmount,
      commissionPerOp: finalCommisionBuyer,
    });

    setSellerFunnelData({
      ...sellerFunnelData,
      amount: totalSellerAmount,
      commissionPerOp: finalCommisionSeller,
    });

    setTimeout(() => setLoading(false), Math.random() * 1000 + 1500);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Business Plan",
  });

  //template
  return (
    <div className={`results`}>
      <div className={`results-container ${animation}`}>
        <h2>
          Resultados{" "}
          <img
            className="pdf-printer-btn"
            src={pdfLogo}
            onClick={handlePrint}
            alt=""
          />
        </h2>
        {loading ? (
          <Spinner />
        ) : (
          <div className="results-content result-enter">
            <div style={{ display: "none" }}>
              <Pdf
                fields={fields}
                buyerFunnelData={buyerFunnelData}
                sellerFunnelData={sellerFunnelData}
                ref={printRef}
              />
            </div>
            {renderBuyerFunnel()}
            {renderSellerFunnel()}
          </div>
        )}
        <button className="btn-primary" onClick={handleClick}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default Results;
