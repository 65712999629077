import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Stages from "./components/Stages";
import { getDeviceScreen } from "./tools/deviceScreen";

function App() {
  const [device, setDevice] = useState(null);

  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", () => {
      updateDevice();
    });
  }, []);

  const updateDevice = () => {
    setDevice(getDeviceScreen());
  };

  return (
    <div className="App">
      <Header />
      <h1>Business Plan</h1>
      <main>
        <Stages />
      </main>
      <Footer />
    </div>
  );
}

export default App;
