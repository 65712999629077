import React from "react";

const Spinner = () => {
  return (
    <div className="spinner">
      <img src="EV_Signet_RGB-2.png" alt="" />
      <p>Calculando Business Plan</p>
    </div>
  );
};

export default Spinner;
